import $ from 'jquery';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle';
import Masonry from 'masonry-layout/dist/masonry.pkgd.js';
import jQueryBridget from 'jquery-bridget';

jQueryBridget('masonry', Masonry, $);

function setIframeDimensions (iframe) {
  iframe.height(iframe.width());
}

$(document).ready(function () {
  $(window).scroll(function () {
    $('.navigation').toggleClass('shrink', $(this).scrollTop() > 25);
  });

  setIframeDimensions($('.release iframe'));

  // Image bgs
  $('[data-bg]').each(function (index, element) {
    $(this).css('background-image', 'url(' + $(element).attr('data-bg') + ')');
  });

  // Home Grid
  $('.grid').masonry({
    itemSelector: '.grid-item',
    percentPosition: true,
    columnWidth: '.grid-sizer',
    gutter: '.gutter-sizer'
  });

  // Nresletter opt-in
  $('#newsletterButton').on('click', function () {
    var email = $('#newsletterInput').val();
    if (/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
      window.location = 'http://newsletter.sirion-records.ch/?p=subscribe&id=1&email=' + email;
    }
  });
});

$(window).on('resize', function () {
  setIframeDimensions($('.release iframe'));
});
